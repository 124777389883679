import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import { useCallback, type Dispatch, type SetStateAction } from "react";
import { SignUpSteps } from "../signUpForm";
import mobile from "is-mobile";
import type { GoogleAuthProps, GoogleUserData } from "./entity";

import { useToast } from "~/components/ui/use-toast";
import { useLocation } from "@remix-run/react";

type GoogleAuthComponentProps = Partial<GoogleAuthProps> & {
  setStep?: Dispatch<SetStateAction<SignUpSteps>>;
  setToken?: Dispatch<SetStateAction<string>>;
  signUp?: boolean;
  handleSignIn?: (data: GoogleUserData) => void;
};
export function GoogleAuth({
  signUp = true,
  handleSignIn,
  setStep,
  setUserData,
  setToken,
}: GoogleAuthComponentProps) {
  const { toast } = useToast();
  const registerUser = useCallback(
    async (token: string) => {
      const decodedToken = jwtDecode<{
        email: string;
        given_name: string;
        family_name: string;
      }>(token);

      if (signUp) {
        setStep?.(SignUpSteps.G_AUTH_USER_INFO);
        setToken?.(token);
        setUserData?.((prev) => ({
          ...prev,
          email: decodedToken.email,
          firstName: decodedToken.given_name,
          lastName: decodedToken.family_name,
          token,
        }));
      }
      if (!signUp) {
        await handleSignIn?.({
          email: decodedToken.email,
          firstName: decodedToken.given_name,
          lastName: decodedToken.family_name,
          token,
        });
      }
    },
    [setStep, setToken, setUserData, handleSignIn, signUp],
  );
  const location = useLocation();
  const isMobile = mobile();
  const isInvitationPage = location.pathname.includes("invite");
  const width = isInvitationPage ? 300 : 400;
  return (
    <GoogleLogin
      width={isMobile ? 250 : width}
      onSuccess={(credentialResponse) => {
        if (!credentialResponse.credential) return;
        registerUser(credentialResponse.credential);
      }}
      onError={() => {
        toast({
          title: "Error",
          description: "Login Failed",
          variant: "destructive",
        });
      }}
    />
  );
}
