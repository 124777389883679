import { getFormProps, useForm, useInputControl } from "@conform-to/react";
import { parseWithZod } from "@conform-to/zod";
import { useFetcher, useSearchParams } from "@remix-run/react";
import { useEffect, useState } from "react";
import { z } from "zod";
import { Button } from "~/components/ui/buttons";
import type { CountryCode } from "libphonenumber-js";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";

import { type action } from "../signup";
import { EmailStep } from "./signUp/emailStep";
import { UserInfoStep } from "./signUp/userInfoStep";
import { Card } from "~/components/ui/card";
import { Text } from "~/components/ui/text";
import { cn } from "~/utils";

import { GoogleAuth } from "./googleAuth";
import { GoogleAuthUserInfoStep } from "./signUp/googleAuthUserInfoStep";
import useGoogleAuth from "./googleAuth/useGoogleAuth";
import { safeRoutes } from "~/utils/routes";
import { useToast } from "~/components/ui/use-toast";

export const signUpSchema = z.object({
  email: z
    .string({ required_error: "Email is required" })
    .trim()
    .toLowerCase()
    .email("Email is invalid")
    .min(1),
  firstName: z.string({ required_error: "First Name is required" }).min(1),
  lastName: z.string({ required_error: "Last Name is required" }).min(1),
  phoneNumber: z.string().optional(),
  country: z.string({ required_error: "Country is required" }),
});

export type SignUpDTO = z.infer<typeof signUpSchema>;

export class SignUpError extends Error {
  public errors: any;
  constructor(message: string, errors: any) {
    super(message);
    this.name = "SignUpError";
    this.errors = errors;
  }
}

export enum SignUpSteps {
  EMAIL = "EMAIL",
  USER_INFO = "USER_INFO",
  G_AUTH_USER_INFO = "G_AUTH_USER_INFO",
  PASSWORD = "PASSWORD",
  PASSWORDLESS = "PASSWORDLESS",
}
export const SignUpForm = ({
  onSubmit,
  validateClerkEmail,
  fetcherErrors,
}: {
  onSubmit: (data: any) => Promise<void>;
  validateClerkEmail: (email: string) => Promise<void>;
  fetcherErrors: any;
}) => {
  const fetcher = useFetcher<typeof action>({ key: "sign-up" });
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [signUpStep, setSignUpStep] = useState(SignUpSteps.EMAIL);
  const {
    setUserData,
    setRedirectUrl,
    userData,
    googleSignUp,
    googleAuthErrors,
  } = useGoogleAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [form, fields] = useForm({
    shouldValidate: "onBlur",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: signUpSchema });
    },
  });

  const country = useInputControl(fields.country);
  const email = useInputControl(fields.email);

  useEffect(() => {
    //Altbanq onboarding
    if (searchParams.get("join") && searchParams.get("inviteCode")) {
      const inviteEmail = searchParams.get("join") as string;
      const redirect = searchParams.get("redirect") as string;

      if (searchParams.get("googleAuth")) {
        const firstName = searchParams.get("firstName") as string;
        const lastName = searchParams.get("lastName") as string;
        const token = searchParams.get("token") as string;

        setSignUpStep(SignUpSteps.G_AUTH_USER_INFO);
        if (!userData.email) {
          setRedirectUrl(redirect || safeRoutes.business.home("onboarding"));
          setUserData?.((prev) => ({
            ...prev,
            email: inviteEmail,
            firstName,
            lastName,
            token,
          }));
        }
      } else {
        setSignUpStep(SignUpSteps.USER_INFO);
      }

      email.change(inviteEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { toast } = useToast();
  useEffect(() => {
    if (fetcherErrors && fetcherErrors.phone) {
      setErrors((prev: any) => ({ ...prev, phoneNumber: fetcherErrors.phone }));
    }
  }, [fetcherErrors]);

  async function handleNextStep() {
    if (signUpStep === SignUpSteps.EMAIL) {
      if (!fields.email.value) return;

      try {
        await validateClerkEmail(fields.email.value);
        setSignUpStep(SignUpSteps.USER_INFO);
      } catch (err: any) {
        setErrors(err.errors);
      }
    }
    if (signUpStep === SignUpSteps.G_AUTH_USER_INFO) {
      try {
        setIsSubmitting(true);
        await googleSignUp();
      } catch (error) {
      } finally {
        setIsSubmitting(false);
      }
    }
    if (signUpStep === SignUpSteps.USER_INFO) {
      if (
        !fields.firstName.value ||
        !fields.lastName.value ||
        !fields.country.value
      )
        return;
      //Altbanq email validation
      if (searchParams.get("join")) {
        const params = new URLSearchParams();
        const redirect = searchParams.get("redirect") as string;

        params.set(
          "redirect",
          redirect || safeRoutes.business.home("onboarding"),
        );

        if (searchParams.get("inviteCode")) {
          params.set("inviteCode", searchParams.get("inviteCode") as string);
        }

        setSearchParams(params, {
          preventScrollReset: true,
        });
        try {
          await validateClerkEmail(fields.email.value as string);
        } catch (err: any) {
          toast({
            title: "Error",
            description: err.errors.email[0] || "Something went wrong",
            variant: "destructive",
          });
        }
      }
      let phone = null;
      const country = JSON.parse(fields.country.value);

      if (fields.phoneNumber.value) {
        setErrors({});
        phone = parsePhoneNumber(
          `${country.phone_code}${fields.phoneNumber.value}`,
          {
            defaultCountry: country.code as CountryCode,
          },
        );

        if (!phone) {
          setErrors({
            phoneNumber: ["Phone number is invalid"],
          });
          return;
        }

        const valid = isValidPhoneNumber(
          phone.number,
          country.code as CountryCode,
        );

        if (!valid) {
          setErrors({
            phoneNumber: ["Phone number is invalid"],
          });

          return;
        }
      }

      try {
        setIsSubmitting(true);
        await onSubmit({
          email: fields.email.value,
          firstName: fields.firstName.value,
          lastName: fields.lastName.value,
          country: country.name,
          ...(phone ? { phoneNumber: phone.number } : {}),
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <Card className="m-auto w-full text-black md:w-2/4 md:px-12">
      {signUpStep === SignUpSteps.USER_INFO && (
        <Text color="brand" variant="h2" className="pl-8 text-left">
          User information
        </Text>
      )}
      {signUpStep === SignUpSteps.G_AUTH_USER_INFO && (
        <Text color="brand" variant="h2" className="pl-8 text-left">
          Hi {userData.firstName},
        </Text>
      )}
      <fetcher.Form method="post" {...getFormProps(form)}>
        <div className="flex flex-col gap-3 px-6 py-2 md:p-8">
          <div className="flex w-full flex-col items-center gap-5">
            {signUpStep === SignUpSteps.EMAIL && (
              <>
                <GoogleAuth
                  setStep={setSignUpStep}
                  setUserData={setUserData}
                  signUp
                />
                <div className="mt-2 flex w-full items-center">
                  <div className="w-1/2 border-b border-gray-300" />
                  <span className="px-4 italic text-gray-600">Or...</span>
                  <div className="w-1/2 border-b border-gray-300" />
                </div>
              </>
            )}

            <div className="mt-2 w-full rounded-md border border-gray-300 bg-white p-[12px]">
              <div
                className={cn("", {
                  hidden: signUpStep !== SignUpSteps.EMAIL,
                })}
              >
                <EmailStep fields={fields} errors={errors} />
              </div>

              <div
                className={cn("", {
                  hidden: signUpStep !== SignUpSteps.USER_INFO,
                })}
              >
                <UserInfoStep
                  country={country}
                  fields={fields}
                  errors={errors}
                />
              </div>

              <div
                className={cn("", {
                  hidden: signUpStep !== SignUpSteps.G_AUTH_USER_INFO,
                })}
              >
                <GoogleAuthUserInfoStep
                  setUserData={setUserData}
                  userData={userData}
                  errors={googleAuthErrors}
                />
              </div>
            </div>
          </div>

          <Button
            type="button"
            onClick={handleNextStep}
            variant="primary"
            size="lg"
            isLoading={isSubmitting}
            className="flex w-full items-center gap-4 md:mt-6"
            disabled={isSubmitting}
          >
            Continue
          </Button>
        </div>
      </fetcher.Form>
    </Card>
  );
};
