import { useState } from "react";

import { useToast } from "~/components/ui/use-toast";
import { useClerk } from "@clerk/remix";
import { safeRoutes } from "~/utils/routes";
import type { CountryCode } from "libphonenumber-js";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import type { GoogleUserData } from "./entity";
import { useNavigate, useSearchParams } from "@remix-run/react";

export default function useGoogleAuth() {
  const [userData, setUserData] = useState<GoogleUserData>({
    email: "",
    firstName: "",
    lastName: "...",
    token: "",
    country: JSON.stringify({
      name: "United States",
      code: "US",
      phone_code: "+1",
    }),
    phoneNumber: "",
  });
  const [, setRedirectUrl] = useState<string | null>();
  const [errors, setErrors] = useState({});

  const clerk = useClerk();
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const googleSignIn = async (data: GoogleUserData) => {
    try {
      const _response = await fetch("/api/sign-in-or-sign-up", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, createUserIfNotExists: false }),
      });
      const response = await _response.json();

      if (response.error && response.code === "ACCOUNT_NOT_FOUND") {
        setErrors({
          email: [response.message],
        });

        toast({
          title: "Account not found",
          variant: "destructive",
        });
        return;
      }

      const businessSlug =
        response?.businessPerson?.[0]?.business?.slug || "onboarding";

      const res = await clerk.authenticateWithGoogleOneTap({
        token: data.token,
      });
      await clerk.handleGoogleOneTapCallback(res, {
        signInFallbackRedirectUrl: safeRoutes.business.home(businessSlug),
      });
    } catch (e: any) {
      toast({
        title: "Error",
        description: "Something went wrong. Please try again.",
        variant: "destructive",
      });
    }
  };

  const googleSignUp = async () => {
    const country = JSON.parse(userData?.country || "");
    const phone = parsePhoneNumber(
      `${country.phone_code}${userData.phoneNumber}`,
      {
        defaultCountry: country.code as CountryCode,
      },
    );

    const valid = isValidPhoneNumber(phone.number, country.code as CountryCode);

    if (!valid) {
      setErrors({
        phoneNumber: ["Phone number is invalid"],
      });
      return;
    }
    try {
      const data = await fetch("/api/sign-in-or-sign-up", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phone.number,
          country: country.code,
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName || "...",
          createUserIfNotExists: true,
        }),
      });
      const response = await data.json();
      if (response.error && response.code === "PHONE_NUMBER_NOT_UNIQUE") {
        setErrors({
          phoneNumber: [response.message],
        });
        return;
      }

      const res = await clerk.authenticateWithGoogleOneTap({
        token: userData.token,
      });
      const redirect = searchParams.get("redirect") as string;

      await clerk.handleGoogleOneTapCallback(res, {});
      navigate(redirect || safeRoutes.business.home("onboarding"));
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong. Please try again.",
        variant: "destructive",
      });
    }
  };

  return {
    userData,
    setUserData,
    googleSignUp,
    googleSignIn,
    setRedirectUrl,
    googleAuthErrors: errors,
  };
}
